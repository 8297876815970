import React, { useState } from 'react';
import "../../assets/css/employe/employedashboard.css";
import Header from '../../components/Header';
import arrowIcon from "../../assets/images/icons/arrow.png";
import dashboardIcon from "../../assets/images/icons/dashboard.png";
import Footer from '../../components/Footer';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/CloudUpload'; 
import AddCircleIcon from '@mui/icons-material/AddCircle'; 
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloseIcon from '@mui/icons-material/Close';

const membersList = [
    { id: 1, name: 'Nikhil Shokeen' },
    { id: 2, name: 'Kuldeep Chaudhary' },
    { id: 3, name: 'Dheerak Jeena' },
];

const EmployeDashboard = () => {
    const [label, setLabel] = useState('');
    const [cards, setCards] = useState([]);
    const [newLabel, setNewLabel] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [showMembers, setShowMembers] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(null); // Track the current card index for adding members

    const handleAddCard = () => {
        if (label) {
            setCards([...cards, { label, description: '', image: null, members: [] }]);
            setLabel('');
        }
    };

    const handleEditClick = (event, index) => {
        event.stopPropagation();
        setEditingIndex(index);
        const currentCard = cards[index];
        if (currentCard) {
            setNewLabel(currentCard.label);
        }
    };

    const handleSaveEdit = () => {
        const updatedCards = [...cards];
        if (editingIndex !== null) {
            updatedCards[editingIndex] = {
                ...updatedCards[editingIndex],
                label: newLabel,
            };
            setCards(updatedCards);
        }
        setEditingIndex(null);
        setNewLabel('');
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setNewLabel('');
    };

    const handleAccordionChange = (index) => (event, isExpanded) => {
        if (editingIndex === null) {
            setExpanded(isExpanded ? index : false);
        }
    };

    const handleImageUpload = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const updatedCards = [...cards];
                updatedCards[index].image = reader.result; // Store image in the specific card
                setCards(updatedCards);
            };
            reader.readAsDataURL(file);
        }
    };

    const toggleMembersList = (index) => {
        setCurrentCardIndex(index);
        setShowMembers(!showMembers);
    };

    const handleMemberClick = (member) => {
        if (currentCardIndex !== null) {
            const updatedCards = [...cards];
            if (!updatedCards[currentCardIndex].members.includes(member.name)) {
                updatedCards[currentCardIndex].members.push(member.name);
                setCards(updatedCards);
            }
        }
        setShowMembers(false);
    };
    console.log(cards,'cards')

    return (
        <>
            <Header title="Dashboard" arrowIcon={arrowIcon} />
            <div className="ContentContainer">
                <div className="InputContainer">
                    <input 
                        type="text" 
                        placeholder='Add Cards....' 
                        value={label}
                        onChange={(e) => setLabel(e.target.value)} 
                    />
                    <button className='AddCardBtn' onClick={handleAddCard}>
                        <AddIcon />
                    </button>
                </div>
                <div className="CardsContainer">
                    {cards.map((card, index) => (
                        <Accordion 
                            key={index} 
                            expanded={expanded === index} 
                            onChange={handleAccordionChange(index)}
                        >
                            <AccordionSummary
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                onClick={() => handleAccordionChange(index)}
                                className='m-0'
                            >
                                <Typography sx={{ color: 'text.secondary', width: '100%' }}  className='AccordianTitle'>
                                    {editingIndex === index ? (
                                        <input 
                                            type="text" 
                                            value={newLabel} 
                                            onChange={(e) => setNewLabel(e.target.value)} 
                                        />
                                    ) : (
                                        card.label
                                    )}
                                </Typography>
                                <Typography sx={{  flexShrink: 0 }}>
                                    {editingIndex === index ? (
                                        <button className='AddCardBtn' onClick={handleSaveEdit}>
                                            Save
                                        </button>
                                    ) : (
                                        <button 
                                            className='AddCardBtn EditTitleBtn' 
                                            onClick={(e) => handleEditClick(e, index)} 
                                        >
                                            <EditIcon />
                                        </button>
                                    )}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="DashDeatilContain">
                                <Typography>
                                    {/* <h4>{card.label}</h4> */}
                                    <div className="formGroup">
                                        <label htmlFor={`desc-${index}`}>Description</label>
                                        <textarea 
                                            rows='4'
                                            name="desc" 
                                            id={`desc-${index}`} 
                                            value={card.description}  
                                            onChange={(e) => {
                                                const updatedCards = [...cards];
                                                updatedCards[index].description = e.target.value;
                                                setCards(updatedCards);
                                            }} 
                                        />
                                        <div className='Containmember'>
                                            <label htmlFor={`upload-${index}`}>
                                                <IconButton component="span">
                                                    <UploadIcon />
                                                </IconButton>
                                                Upload Image
                                            </label>
                                            <input 
                                                type="file" 
                                                id={`upload-${index}`} 
                                                accept="image/*" 
                                                onChange={(e) => handleImageUpload(e, index)} 
                                                style={{ display: 'none' }} 
                                            />
                                            <IconButton onClick={() => toggleMembersList(index)}>
                                                <PeopleAltIcon />
                                            </IconButton>
                                            Add Members
                                            {showMembers && currentCardIndex === index && (
                                        <div className="membersList">
                                            <h5>Select Members <button className='CloseBtnMember' onClick={() => setShowMembers(!showMembers)}><CloseIcon/></button></h5>
                                            <ul>
                                                {membersList.map((member) => (
                                                    <li key={member.id} onClick={() => handleMemberClick(member)}>
                                                        {member.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                        </div>
                                        {card.image && <img src={card.image} alt="Uploaded"  className='DashImgAccordian'/>}
                                        {card.members.length > 0 ? 
                                    <div className='ShowMembersContaon'>
                                
                                        
                                        <h5>Members</h5>
                                            <ul>
                                                {card.members.map((member, idx) => (
                                                    <li key={idx}>{idx +1}. {member}</li>
                                                ))}
                                            </ul>
                                        
                                        {/* {card.members.length > 0 ? (
                                            <ul>
                                                {card.members.map((member, idx) => (
                                                    <li key={idx}>{member}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No members added.</p>
                                        )} */}
                                    </div>
                                    : ''}

                                  

                                        <div className="mt-2 text-center">
                                        <button onClick={handleSaveEdit} className='DashBtn save'>Save</button>
                                        <button onClick={handleCancelEdit} className='DashBtn cancel ms-2'>Cancel</button>
                                        </div>
                                    </div>
                                </Typography>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>

            <Footer modifiedItem={{ index: 1, icon: dashboardIcon, label: "Dashboard", link: "/employe/dashboard" }} />
        </>
    );
};

export default EmployeDashboard;
