import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate, useParams } from 'react-router-dom';
import "../../assets/css/employe/employefollowup.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import callNowIcon from "../../assets/images/icons/callnow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { apiCall } from '../../services/apiservices.jsx';
import CustomSnackbar from '../../components/snackBar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ToastContainer, toast } from "react-toastify";

const EmployeFollowUp = () => {
    const {ticket_id} = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const location = useLocation(); 
    const { id, cDate, issue_type, comment, createdTime, statusData } = location.state || {}; 
    const [ticketId, setTicketIdData] = useState("");  
    const [getDate, setDate] = useState("");  
    const [remark, setRemarkData] = useState("");  
    const [issueType, setIssueType] = useState("");  
    const [timeCreated, settimeCreated] = useState("");  
    const [getStatusData, setStatusData] = useState("");
    const [followUpRemark, setFollowUpRemark] = useState("");
    const [statusOnPostRequest, setStatusOnPostRequest] = useState("");
    const [submitModal, setSubmitModal] = useState(false);
    const [mobile, setMobile] = useState(''); 
    const [followupName, setFollowupName] = useState();
    const [customerName, setCustomerName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [newStatus, setNewStatus] = useState('');

    function closeModalOnSubmit(){
      setSubmitModal(false)
    }
    
    const showSnackbar = (message, severity = 'success') => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setSnackbarOpen(false);
    };
  

    const handleStatusChange = (event) => {
      setNewStatus(event.target.value);
    };

    useEffect(() => {
        setTicketIdData(id);
        setDate(cDate);
        setRemarkData(comment);
        setIssueType(issue_type);
        settimeCreated(createdTime);
        setStatusData(statusData);
        setNewStatus(statusData);
    }, []);

    const modifiedItem = {
        index: 1,
        icon: requestIcon,
        label: "Followup",
        link: `/employe/followup/${ticketId}`,
    };

    const handleSaveForTicket = async () => {
      if(newStatus === 'Pending'){
        return toast.error('Status can only be In-Process and Resolved');
      }
        try {

            const payload = {
                ticketId: ticketId,
                status: newStatus,
                followUpRemark: followUpRemark,
                issueType:issueType,
                customerRemark :comment
            };

            const res = await apiCall({ endpoint: "employee/postDataForTicketUpdate", method: 'POST', payload: payload });

            if(res){
              setStatusOnPostRequest(res.message)
              setSubmitModal(true);
              
            }else{
              showSnackbar('Internal Server Error', 'error');
            }

        } catch (err) {
            console.log(err);
        }
    };

    // get individual ticket data
      useEffect(()=>{
        const fetchData = async(ticket_id)=>{
        const endpoint = `/employee/getEmployeeTicketMobName/${ticket_id}`;

        try{
          const res = await apiCall({endpoint: endpoint, method: 'GET'});
          if(res.success){
            setMobile(res.data.mobile);
            setFollowupName(res.data.followUp);
            setCustomerId(res.data.customerId);
            setCustomerName(res.data.customerName)
          }else{
            console.log('Failed to fetch data');
          }
        }catch(error){
          console.log('failed to call API', error)
        }
        }
        fetchData(ticket_id);
      }, [ticket_id])

    return (
        <>
        <Header title="Followup" arrowIcon={arrowIcon} />
          <div className="ContentContainer">
    
            <div className="FollowUpHeader mt-3 d-flex justify-content-between">
                <h4>Ticket ID : <span>{ticketId}</span></h4>
                <span className={`CardCount ${getStatusData === 'In-Process' ? 'Colorblue' : getStatusData === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{getStatusData}</span>
            </div>
  
            <div className="TrackDetails RequestDetails">
              <div className="d-flex justify-content-between Flexwrp320">
              <div className="TicketDate TrackMessageCard">
                <h4>Ticket Date</h4>
                <p>{getDate} {timeCreated}</p>
              </div>
              <div className="TrackMessageCard Status">
                <h4>Status</h4>
                <select 
                  name="status" 
                  id="status" 
                  value = {newStatus}
                  onChange={handleStatusChange}
                >
                  {getStatusData === 'In-Process' ? (
                    <>
                      <option value="In-Process">In-Process</option>
                      <option value="Resolved">Resolved</option>
                    </>
                  ) : (
                    <>
                      <option value="Pending">Pending</option>
                      <option value="In-Process">In-Process</option>
                      <option value="Resolved">Resolved</option>
                    </>
                  )}
                </select>

              </div>
              </div>
   
                <div className="row gy-3 pt-3">
                    <div className="col-7">
                        <div className="TrackMessageCard">
                          <h4>Customer Name :</h4>
                          <p>{customerName}</p>
                        </div>
                    </div>
                    <div className="col-5 text-end">
                        <div className="TrackMessageCard">
                          <h4>Customer ID :</h4>
                          <p>{customerId}</p>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="TrackMessageCard">
                          <h4>Category :</h4>
                          <p>{issueType}</p>
                        </div>
                    </div>
                    <div className="col-5 text-end">
                        <div className="TrackMessageCard">
                          <h4>Mobile :</h4>
                          <p>{mobile}</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4>Customer Remarks : </h4>
                            <textarea name="comment" id="comment" value={remark} readOnly></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4>Followup Remarks : </h4>
                            <textarea
                                name="comment1"
                                id="comment1"
                                placeholder=""
                                value={followUpRemark}
                                onChange={(e) => setFollowUpRemark(e.target.value)} // Update follow-up remark on change
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                    <div className="TrackMessageCard">
                      <h4>Followed By : <span>{followupName}</span></h4>
                    </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard text-center FollowupBtnContain mt-0">
                        <Link to={`/employe/followup/history/${ticketId}`}><button className="RequestModalBtn HistoryBtn" type="submit">History</button></Link>
                        <a href="tel:18001370808" target="_blank" rel='noreferrer'><button className="RequestModalBtn CallNowBtn" type="submit"><span><img src={callNowIcon} alt="icon" /></span> Call Now </button></a>
                        <button className="RequestModalBtn" type="submit" onClick={handleSaveForTicket}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`SubmitModalContain ${submitModal ? '' : 'd-none'}`}>
            <div className="SubmitModal">
                <TaskAltIcon className='SubmitIConCheck'/>
                <p>Ticket has been {statusOnPostRequest}.</p>
                <button onClick={() => {closeModalOnSubmit();
                  navigate(`/employee/${getStatusData}`);
                }}>OK</button>
            </div>
            </div>
          </div>
        <ToastContainer />

          <Footer modifiedItem={modifiedItem} />
          <CustomSnackbar 
            open={snackbarOpen} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage} 
            severity={snackbarSeverity} 
          />
        </>
      );
  };

export default EmployeFollowUp;
