import React, {useState} from 'react'
import "../../assets/css/customer/forgotpassword.css";
import Logo from '../../assets/images/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { apiCall } from "../../services/apiservices";
import { ToastContainer, toast } from "react-toastify";



const ForgotPassword = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [mobileOtpVisible, setOtpInputVisible ] = useState(false);
    const [credentials, setCredentials] = useState({
        userId: '',
        mobileOtp: ''
    });
    const [submitButtonCondition, setSubmitButtonCondition ] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};

        // check if user id exists or not
        if(!credentials.userId.trim()){
            formErrors.userId = "User Id is required"
        }

        // check for otp 
        if(mobileOtpVisible && !credentials.mobileOtp.trim()){
            formErrors.mobileOtp = "Mobile OTP is required"
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    const handleChanges = (event) =>{
        const {name, value} = event.target;
        setCredentials((prevState) => ({...prevState, [name]: value}));
    }


    // generate otp
    const setMobileOtpVisible = async () =>{
        let formErrors = {};
        if(!credentials.userId.trim()){
            formErrors.userId = "User Id is required"
        }else{
            try{
                const endpoint = 'auth/resetOtpGenerate';
                const payload = {
                    userId: credentials.userId
                };
                const res = await apiCall({endpoint: endpoint, method: 'POST', payload});
                if(!res.success){
                    toast.error(res.message);
                }else{
                    toast.success(res.message);
                    setOtpInputVisible(true);
                    setSubmitButtonCondition(true);
                }
            }catch(error){
                toast.error('Failed To generated OTP');
                console.log('Error on generating OTP: ', error);
            }
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    // check and submit otp
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(!validateForm()){
            return;
        }
        try{
            let endpoint = `auth/verifyResetOtp`;
            let payload = {
                userId: credentials.userId,
                mobileOtp: credentials.mobileOtp
            }
            let res = await apiCall({endpoint: endpoint, method: 'POST', payload: payload});
            if(!res.success){
                toast.error(res.message);
            }else{
                navigate(`/resetPassword`, {state: {rowData: res.token}}); 
            }
        }catch(error){
            toast.error("Failed To Verify Data");
            console.log('failed to load credentials', error);
        }
    }
  return (
    <>
    <div className="ForgotPswrdContain">
        <div className="ForgotPswrdLogo">
            <img src={Logo} alt="img" />
        </div>
        <div className="ForgotPswrdCard">
            <div className="ForgotPswrdTitle">
                <h4>Forgot Password</h4>
            </div>
            <div className="ForgotPswrdGroup">
                <label htmlFor="id">PAN Card <span className="RequieredStar">*</span></label>
                <input type="text" placeholder='Enter Your PAN Card'/>
            </div>
            <div className="ForgotPswrdDivider"><span>OR</span></div>

            <div className="LoginGroup">
                <label htmlFor="userId">User Id <span className="RequieredStar">*</span></label>
                <div className="ProcessGroup">
                  <input
                    type="text"
                    id="userId"
                    name="userId"
                    value={credentials.userId}
                    placeholder="Enter Your User Id"
                    onChange={handleChanges}
                    />
                  <button
                    type="button"
                    className="GetOtpBtn"
                    onClick={setMobileOtpVisible}
                  >
                    Get OTP
                  </button>
                </div>
                {errors.userId && <span className="error">{errors.userId}</span>}
              </div>

              {mobileOtpVisible && (
                <div className="LoginGroup">
                  <label htmlFor="mobileOtp">Mobile OTP</label>
                  <input
                    type="text"
                    id="mobileOtp"
                    name="mobileOtp"
                    value={credentials.mobileOtp}
                    placeholder="Enter Mobile OTP"
                    onChange={handleChanges}
                  />
                  {errors.mobileOtp && <span className="error">{errors.mobileOtp}</span>}
                </div>
              )}

              {submitButtonCondition && (
              <div className="SignUpGroup SignUpBtn">
                <button type="submit" onClick={handleSubmit} >Submit</button>
              </div>
              )}
            
            {/* <div className="ForgotPswrdGroup">
                <label htmlFor="name">User Id <span className="RequieredStar">*</span></label>
                <input type="text" placeholder='Enter Your User Id' name='userId' value={credentials.userId} onChange={handleChanges}/>
                {errors.userId && <span className='error'>{errors.userId}</span>}
            </div> */}
            {/* <div className={`ForgotPswrdGroup ${open === true ? '' : 'd-none'}`}>
                <label htmlFor="otp">OTP</label>
                <input type="text" placeholder='Enter Your OTP'/>
            </div>




            <div className="ForgotPswrdGroup ForgotPswrdBtn">
                <Link>
                <button onClick={() => setOpen(!open)} type='submit'>Send</button>
                </Link>
            </div> */}

            
            <div className="SignUpGroup signUp">
                <p>Already have an account? <Link to='/'>Login </Link></p>
            </div>
        </div>
    </div>
        <div className="ForgotPswrdFooter">
        <p>
          POWERED BY: <span>MANTHAN IT SOLUTIONS</span>
        </p>
        </div>
        <ToastContainer />
        </>
  )
}

export default ForgotPassword