import React, { useState, useEffect } from "react";
import "../../assets/css/customer/profile.css";
import editIcon from "../../assets/images/icons/edit.png";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import ProfileIcon from "../../assets/images/icons/defaultimg.png";
import Profile1Icon from "../../assets/images/icons/profile1.png";
import eyeIcon from "../../assets/images/icons/eye.png";
import Header from "../../components/Header";
import { apiCall } from "../../services/apiservices";
import CustomSnackbar from "../../components/snackBar";
import { useUIContext } from "../../context";
import SetLoadingScreen from "../../components/SetLoader";

const Profile = () => {
  const modifiedItem = {
    index: 1,
    icon: Profile1Icon,
    label: "Profile",
    link: "/profile",
  };
  const { SelectImage, setSelectedImage } = useUIContext();

  // state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [userName, setUserName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const [isNewEdit, setIsEditing] = useState(false);

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const logoutfunc = () => {
    try {
      const tokendata = localStorage.getItem("token");
      if (tokendata) localStorage.removeItem("token");
    } catch (err) {
      console.log("Error while logging out", err);
    }
  };

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      showSnackbar("New password and Confirm password do not match.", "error");
      return;
    }

    setloading(true);
    try {
      if (!oldPassword) {
        showSnackbar("Old password is required.", "error");
        return;
      }

      const payload = { oldPassword, newPassword };
      const response = await apiCall({
        endpoint: "auth/updatepassword",
        method: "post",
        payload,
      });

      if (response.success) {
        setloading(false);
        showSnackbar("Password updated successfully!");
        logoutfunc();
        window.location.href = "/";
      } else {
        setloading(false);
        showSnackbar(response.msg || "Old password is wrong", "error");
      }
    } catch (error) {
      setloading(false);
      console.error("Error updating password:", error);
      showSnackbar(
        "An error occurred while updating the password. Please try again.",
        "error"
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        uploadProfileImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadProfileImage = async (file) => {
    setloading(true);
    const formData = new FormData();
    formData.append("file", file);
    setloading(true);
    try {
      const response = await apiCall({
        endpoint: "auth/uploadprofile",
        method: "post",
        payload: formData,
      });

      if (response) {
        setloading(false);
        setSelectedImage(response.filePath);
        showSnackbar("Profile image uploaded successfully");
      } else {
        setloading(false);
        showSnackbar("Failed to upload profile image", "error");
      }
    } catch (error) {
      setloading(false);
      console.error("Error uploading image:", error);
      showSnackbar("Error uploading image", "error");
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    setloading(true);
    try {
      const response = await apiCall({
        endpoint: "auth/profiledata",
        method: "GET",
      });

      if (response.success) {
        setloading(false);
        // setprofiledata(profileData);
        setUserName(response.data[0].customer_name);
        setContactPerson(response.data[0].contact_person);
        setPanNumber(response.data[0].company_pan);
        setGstNumber(response.data[0].company_gst_number);
        setCity(response.data[0].city);
        setState(response.data[0].state);
        setCompanyAddress(response.data[0].company_address);
        setCompanyName(response.data[0].company_name);
        setSelectedImage(response.data[0].customer_profile || ProfileIcon);
      }
    } catch (error) {
      setloading(false);
      showSnackbar("Error fetching profile data", "error");
    }
  };

//   handle edit click
const handleEditClick = async (e) => {
    e.preventDefault();
    if(isNewEdit){
      try{
          let payload = {
              userName,
              contactPerson,
              panNumber,
              gstNumber,
              city,
              state,
              companyAddress,
          }
  
          const endpoint = 'auth/updateProfile';
          const res = await apiCall({endpoint: endpoint, method: 'POST', payload});
          if(res.success){
              showSnackbar('Profile Updated Successfully');
              fetchdata();
          }
      }catch(error){
          console.log('Error on uploading user data', error);
          showSnackbar('Error on uploading profile data', "error");
      }
    }
    setIsEditing(!isNewEdit);
};

  return (
    <>
      <SetLoadingScreen loading={loading} />
      <Header title="Manage Profile" arrowIcon={arrowIcon} />

      <div className="ContentContainer">
        <section className="ProfileContain">
          <div className="Profile_image">
            <div className="ImgP">
              <img src={SelectImage} alt="Profile" />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <label
              htmlFor="file-input"
              className="EditProfile"
              style={{
                cursor: "pointer",
              }}
            >
              <img src={editIcon} alt="Edit" />
            </label>
          </div>

          <h4 className="CmpnyTitle">{companyName ? companyName : ""}</h4>

          <div className="Profile_content">
            <div className="ContentCard">
              <div className="row gy-4 py-2">
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="userName">User Name</label>
                    <input type="text" value={userName ? userName : ""} onChange={(e) => setUserName(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="contactPerson">Contact Person</label>
                    <input type="text" value={contactPerson ? contactPerson : ''} onChange={(e) => setContactPerson(e.target.value)} readOnly = {!isNewEdit}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="pan">Pan</label>
                    <input type="text" value={panNumber ? panNumber : ""} onChange={(e) => setPanNumber(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="gstNumber">GST Number</label>
                    <input type="text" value={gstNumber ? gstNumber : ""} onChange={(e) => setGstNumber(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="city">City</label>
                    <input type="text" value={city ? city : ''} onChange={(e) => setCity(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="state">State</label>
                    <input type="text" value={state ? state : ''} onChange={(e) => setState(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className={!isNewEdit ? 'Pcontent_card1' : 'Pcontent_card11'}>
                    <label htmlFor="billing">Billing Address</label>
                    <input type="text" value={companyAddress ? companyAddress : ""} onChange={(e) => setCompanyAddress(e.target.value)} readOnly={!isNewEdit}/>
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-center editBtn">
                    <button onClick={handleEditClick} className="myBtn">{isNewEdit ? "Save" : "Edit"}</button>
                  </div>
              </div>
            </div>
          </div>

          <div className="PasswordChange">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Privacy and Security
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse "
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row gy-4 py-2">
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>Old Password</h4>
                          <div className="PinputContain">
                            <input
                              placeholder="Enter old password"
                              type={showOldPassword ? "text" : "password"}
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <button
                              className="ShowPasswordbtn"
                              onClick={() =>
                                setShowOldPassword(!showOldPassword)
                              }
                            >
                              <img src={eyeIcon} alt="icon" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>New Password</h4>
                          <div className="PinputContain">
                            <input
                              placeholder="Enter new password"
                              type={showNewPassword ? "text" : "password"}
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <button
                              className="ShowPasswordbtn"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              <img src={eyeIcon} alt="icon" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>Confirm Password</h4>
                          <div className="PinputContain">
                            <input
                              placeholder="Confirm Password"
                              type={showConfirmPassword ? "text" : "password"}
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <button
                              className="ShowPasswordbtn"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              <img src={eyeIcon} alt="icon" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <button
                            className="PasswordChangebtn"
                            onClick={handlePasswordUpdate}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer modifiedItem={modifiedItem} />
      <CustomSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default Profile;