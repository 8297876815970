import React from 'react'
import "../../assets/css/admin/admin_invoice_history.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import Profile1Icon from "../../assets/images/icons/profile1.png";
import invoiceIcon from '../../assets/images/icons/invoice.png'
import RadioButtonGroup from '../../components/RadioGroup';
import Header from '../../components/Header';
import DashboardCards from '../../components/DashboardCard';
import pdfIcon from '../../assets/images/icons/pdf.png';
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

const AdminInvoiceHistory = () => {
  const modifiedItem = {
    index: 1,
    icon: Profile1Icon,
    label: "Invoice History",
    link: "/admin/invoicehistory",
  };
  const ticketData = [
    { count: 100, label: 'Total', className: '' },
    { count: 40, label: 'Paid', className: 'resolve' },
    { count: 60, label: 'Pending', className: 'pending' },
  ];

  const [open, setOpen] = React.useState(false);
  const status = 'Paid';
  return (
    <>
    <Header title="Invoice History" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
      <DashboardCards title="Invoice Dashboard" invoiceIcon={invoiceIcon} ticketData={ticketData} />
    

      {/* radio button start */}
      
      <div className="TrackRequest mt-3">
            <div className="TrackRequestHeader">
                <RadioButtonGroup options={["PREPAID", "POSTPAID"]}  initialOption="PREPAID"/>
            </div>
        </div>
        {/* <InvoiceTable/> */}
        <div className="TrackDetails RequestDetails">
        <span className={`CardCount ${status === 'Paid' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
            <div className="row gy-3">
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>Invoice Number :  <span>1010</span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>Invoice Date :  <span>03-10-2024 </span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>PDF :  <span><img src={pdfIcon} alt="icon" /></span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <button className="RequestModalBtn" onClick={() => setOpen(!open)}>Contact Us </button>
                    </div>
                </div>
                <div className={`RequestModalContain ${open === false ? 'd-none' : ''}`}>
                <div className="RequestModal">
                  <h4 className="CardTitle">Contact Details <button className="CloseBtn" onClick={() => setOpen(!open)}><CloseIcon/></button></h4>
                  <ul>
                    <li><a href="mailto:cs.team@manthanitsolutions.com" target="_blank"  className="Word_breack" rel="noopener noreferrer"><MailIcon/> cs.team@manthanitsolutions.com</a></li>
                    <li><a href="tel:18001370808"><CallIcon/> 1800-137-0808</a></li>
                  </ul>
                </div>
                </div>
            </div>
        </div>

        
      {/* radio button end */}


      </div>

      
      <Footer modifiedItem={modifiedItem} />
    </>
  )
}

export default AdminInvoiceHistory