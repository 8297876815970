
import { BrowserRouter, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import '../src/assets/css/index.css'
import { useEffect,useCallback } from 'react';
import Login from './pages/Login';
import Process from './pages/Process';
import Home from './pages/customer/Home';
import AdminHome from './pages/admin/AdminHome';
import EmployeHome from './pages/employe/EmployeHome';
import Profile from './pages/customer/Profile';
import HelpSupport from './pages/customer/HelpSupport';
import Contact from './pages/customer/Contact';
import TrackRequest from './pages/customer/TrackRequest';
import InvoiceHistory from './pages/customer/InvoiceHistory';
import Recharge from './pages/customer/Recharge';
import Bot from './pages/customer/Bot';
import TrackMessage from './pages/customer/TrackMessage';
import AccountLink from './pages/customer/AccountLink';
import SignUp from './pages/customer/SignUp';
import ForgotPassword from './pages/customer/ForgotPassword';
import ResetPassword from './pages/customer/RestPassword';
import AdminInvoiceHistory from './pages/admin/AdminInvoiceHistory';
import ToDoRequest from './pages/admin/ToDoRequest';
import Followup from './pages/admin/Followup';
import FollowUpHistory from './pages/admin/FollowUpHistory';
import EmployePending from './pages/employe/EmployePending';
import AdminPending from './pages/admin/AdminPending';
import EmployeFollowUp from './pages/employe/EmployeFollowUp';
import EmployeFollowUpHistory from './pages/employe/EmployeFollowUpHistory';
import EmployeAccountLink from './pages/employe/EmployeAccountLink';
import EmployeTrackMessage from './pages/employe/EmployeTrackMessage';
import AdminAccountLink from './pages/admin/AdminAccountlink';
import AdminTrackMessage from './pages/admin/AdminTrackMessage';
import ChatPreview from './pages/employe/ChatPreview' 
import Chat from './pages/employe/Chat';
import Performance from './pages/employe/Performance';
import ChatList from './pages/employe/ChatList';
import ProfilePreview from './pages/employe/ProfilePreview';
import EmployeTarget from './pages/employe/EmployeTarget';
import Graph from './pages/employe/Graph';
import { Me } from './services/authService';
import AuthGuard from './guards/authGuards';
import AdminGuard from './guards/adminGuard';
import EmployeeGuard from './guards/employeeGuard';
import CustomerGuard from './guards/customerGuard';
import EmployeDashboard from './pages/employe/EmployeDashboard';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const excludeUrl = ['/signup', '/forgotpassword', '/resetPassword'];

  const handleNavigation = useCallback((userType) => {
    if (location.pathname === '/' && userType === 'admin') {
      navigate('/admin/home');
    } else if (location.pathname === '/' && userType === 'customer') {
      navigate('/home');
    }else if (location.pathname === '/' && userType === 'employee') {
      navigate('/employe/home');
    }
  }, [location.pathname, navigate]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await Me(); 
        if(!userData){
          localStorage.removeItem("user-cred"); 
          navigate('/');
          return
        }
        const userType = userData?.data?.user_role; 
        handleNavigation(userType); 
      } catch (error) {
        localStorage.removeItem("user-cred"); 
        navigate('/');
      }
    };

    // Only fetch data on page refresh or component mount
    if(!excludeUrl.includes(location.pathname)){
      fetchData();
    }
  }, []); 
   

 
  return (

          <Routes>
            <Route path="/home" element={ <AuthGuard><CustomerGuard><Home /></CustomerGuard></AuthGuard>} />
            <Route path="/admin/home" element={ <AuthGuard><AdminGuard><AdminHome /></AdminGuard></AuthGuard>} />
            <Route path="/employe/home" element={ <AuthGuard><EmployeeGuard><EmployeHome /></EmployeeGuard></AuthGuard>} />
            <Route path="/profile" element={<AuthGuard><Profile /></AuthGuard>} />
            <Route path="/helpsupport" element={<AuthGuard><HelpSupport /></AuthGuard>} />
            <Route path="/contact" element={<AuthGuard><Contact /></AuthGuard>} />
            <Route path="/trackrequest" element={<AuthGuard><TrackRequest /></AuthGuard>} />
            <Route path="/invoicehistory" element={<AuthGuard><InvoiceHistory /></AuthGuard>} />
            <Route path="/recharge" element={<AuthGuard><Recharge /></AuthGuard>} />
            <Route path="/bot" element={<AuthGuard><Bot /></AuthGuard>} />
            <Route path="/trackmessage" element={<AuthGuard><TrackMessage /></AuthGuard>} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/process" element={<Process />} />
            <Route path="/accountlink" element={<AuthGuard><AccountLink /></AuthGuard>} />
            {/* admin page url start */}
            <Route path="/admin/invoicehistory" element={<AuthGuard><AdminInvoiceHistory /></AuthGuard>} />
            {/* <Route path="/admin/todorequest/:selectOption" element={<AuthGuard><ToDoRequest /></AuthGuard>} /> */}
            <Route path="/admin/followup/:userTicketId" element={<AuthGuard><Followup /></AuthGuard>} />
            <Route path="/admin/followup/history/:ticketId" element={<AuthGuard><FollowUpHistory /></AuthGuard>} />
            <Route path="/admin/accountlink" element={<AuthGuard><AdminAccountLink /></AuthGuard>} />
            <Route path="/admin/trackmessage" element={<AuthGuard><AdminTrackMessage /></AuthGuard>} />
            <Route path="/admin/todorequest" element={<AuthGuard><ToDoRequest /></AuthGuard>} />
            <Route path="/admin/:status" element={<AuthGuard><AdminPending /></AuthGuard>} />
            {/* emplore page url start */}
            <Route path="/employee/:status" element={<AuthGuard><EmployePending /></AuthGuard>} />
            <Route path="/employe/followup/:ticket_id" element={<AuthGuard><EmployeFollowUp /></AuthGuard>} />
            <Route path="/employe/followup/history/:ticketId" element={<AuthGuard><EmployeFollowUpHistory /></AuthGuard>} />
            <Route path="/employe/accountlink" element={<AuthGuard><EmployeAccountLink /></AuthGuard>} />
            <Route path="/employe/trackmessage" element={<AuthGuard><EmployeTrackMessage /></AuthGuard>} />
            <Route path="/employe/chat" element={<AuthGuard><ChatList /></AuthGuard>} />
            <Route path="/employe/chatPreview" element={<AuthGuard><ChatPreview /></AuthGuard>} />
            <Route path="/employe/chat/:id" element={<AuthGuard><Chat /></AuthGuard>} />

            {/* url for chat profile */}
            <Route path="/chatProfile/:id" element={<AuthGuard><ProfilePreview /></AuthGuard>} />
            
            <Route path="/employe/performance" element={<AuthGuard><Performance /></AuthGuard>} />
            <Route path="/employe/target" element={<AuthGuard><EmployeTarget /></AuthGuard>} />
            <Route path="/employe/graph" element={<AuthGuard><Graph /></AuthGuard>} />
            <Route path="/employe/dashboard" element={<AuthGuard><EmployeDashboard /></AuthGuard>} />
            {/* <Route path="/admin/invoicehistory" element={<AuthGuard><AdminInvoiceHistory /></AuthGuard>} />
            <Route path="/admin/todorequest" element={<AuthGuard><ToDoRequest /></AuthGuard>} />
            <Route path="/admin/followup" element={<AuthGuard><Followup /></AuthGuard>} />
            <Route path="/admin/followup/history" element={<AuthGuard><FollowUpHistory /></AuthGuard>} /> */}
          </Routes>
  )
   
};

const App = () => {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
};

export default App;
