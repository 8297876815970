import React, {useEffect, useState} from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useUIContext } from "../../context";
import '../../assets/css/employe/profilePreview.css';
import arrowIcon from "../../assets/images/icons/arrow.png";
import ProfileIcon from "../../assets/images/icons/profile1.png";
// import Close from "../../assets/images/icons/close_w.png"
import { apiCall } from "../../services/apiservices";
import { ToastContainer, toast } from "react-toastify";




const ProfilePreview = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {setHome, SelectImage, chatUserProfile, setChatUserProfile} = useUIContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState('');
    useEffect(()=> {
        const fetchData = async ()=>{
            try{
                const endpoint = `chat/profilePreview?userId=${id}`;
                
                const response = await apiCall({endpoint: endpoint, method: 'GET'});

                if(response.success){
                    setUserEmail(response.data.customer_email_id);
                    setUserName(response.data.customer_name);
                    setUserProfileImage(response.data.customer_profile);
                }

            }catch(error){
                console.log('Error on fetching data: ', error);
            }
        };
        fetchData();
    }, [])

    const handleClick = (event) => {
        event.preventDefault();
        navigate(-1);
    }
    
    const previewModal = () => {
        if(userProfileImage){
            setIsModalOpen(true);
        }else{
            return toast.error('No profile photo');
        }
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            {!isModalOpen && (
                <>
                    <section id="Header" className="ContactHeader">
                        <div className="profile_text">
                            <div className="HeaderContain ProfileHeader">
                                <div className="ProfileIcon bg_transparant">
                                    <Link onClick={handleClick}>
                                        <img src={arrowIcon} alt="back" />
                                    </Link>
                                </div>
                                <h2 className="HeaderTitle">Profile Preview</h2>
                            </div>
                        </div>
                    </section>
    
                    <div className="ContentContainer">
                        <div className="userProfileView">
                            <img
                                src={userProfileImage ? userProfileImage : ProfileIcon}
                                alt="Profile"
                                onClick={previewModal}
                            />
                            <h2>
                                Name: <span>{userName}</span>
                            </h2>
                            <h2>
                                Email: <span>{userEmail}</span>
                            </h2>
                        </div>
                    </div>
                </>
            )}
    
            {isModalOpen &&(
                <>
                    <section id="Header1" className="ContactHeader">
                        <div className="profile_text">
                            <div className="HeaderContain ProfileHeader">
                                <div className="ProfileIcon bg_transparant">
                                    <Link onClick={closeModal}>
                                        <img src={arrowIcon} alt="back" />
                                    </Link>
                                </div>
                                <h2 className="HeaderTitle">{userName}</h2>
                            </div>
                        </div>
                    </section>

                    <div className="ContentContainer1">
                        <div className="modal_overlay1">
                        <img
                            src={userProfileImage ? userProfileImage : ''}
                            alt="userProfile"
                        />
                        </div>
                    </div>
                </>
            )}
      <ToastContainer />

        </>
    );
    
}

export default ProfilePreview;