import React from 'react'
import "../../assets/css/customer/contact.css";
import Footer from '../../components/Footer'
import callIcon from '../../assets/images/icons/call.png'
import arrowIcon from "../../assets/images/icons/arrow.png";
import emailIcon from "../../assets/images/icons/email.png";
import missedCallIcon from "../../assets/images/icons/missed-call.png";
import Header from '../../components/Header';

const Contact = () => {
    const modifiedItem = {
        index: 1,
        icon: callIcon,
        label: "Contact",
        link: "/contact",
      };
  return (
    <>
    <Header title="Contact US" arrowIcon={arrowIcon} />
      
      <div className="ContentContainer">
      <div className="Contact_content">
        <div className="row">
        <div className="col-12">
                  <div className="Pcontent_card Note">
                    <p>
                      You can get in touch with us through below platforms. Our Team will reach out to you as soon as possible.
                    </p>
                  </div>
                </div>
        </div>
            <div className="ContentCard">
              <div className="row gy-4 py-2">
                <div className="col-12">
                  <div className="Pcontent_card">
                    <h4>Customer Support</h4>
                    <p className='Word_breack'><img src={emailIcon} alt="icon" /><a href="mailto:cs.team@manthanitsolutions.com" target='_blank' rel='noopener noreferrer'>cs.team@manthanitsolutions.com</a></p>
                  </div>

                </div>
                <div className="col-12">
                  <div className="Pcontent_card">
                    <h4>Technical Support</h4>
                    <p className='Word_breack'><img src={emailIcon} alt="icon" /><a href="mailto:ts.team@manthanitsolutions.com" target='_blank' rel='noopener noreferrer'>ts.team@manthanitsolutions.com</a></p>
                  </div>

                </div>
                <div className="col-12">
                  <div className="Pcontent_card">
                    <h4> Missed Call Number</h4>
                    <p><img src={missedCallIcon} alt="icon" /><a href="tel:18001370808" target='_blank' rel="noopener noreferrer">1800-137-0808</a></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
      </div>
      <Footer modifiedItem={modifiedItem} />
    </>
  )
}

export default Contact