// Import moment-timezone
import moment from 'moment-timezone';

// const getCurrentIST = () => {
//   // Set the timezone to Asia/Kolkata
//   const indiaTime = moment().tz('Asia/Kolkata');
//   const todayDate = indiaTime.format('DD/MM/YYYY');
//   const currTime = indiaTime.format('HH:mm');
  
//   return { currTime, todayDate };
// };

const getCurrentIST = () => {
  const indiaTime = moment().tz('Asia/Kolkata');
  const todayDate = indiaTime.format('DD/MM/YYYY');
  const currTime = indiaTime.format('hh:mm A'); // 12-hour format with AM/PM
  
  return { currTime, todayDate };
};

export default getCurrentIST;
