import React, { useState, useEffect } from 'react';
import '../../assets/css/customer/home.css';
import profileICon from '../../assets/images/icons/profile.png';
import Logo from '../../assets/images/logo.png';
import helpSupportIcon from '../../assets/images/icons/help_support.png';
import Footer from '../../components/Footer.jsx';
import { Link, useNavigate } from 'react-router-dom';
import AutoplaySlider from '../../components/Slider.jsx';
import Header from '../../components/Header.jsx';
import requestIcon from '../../assets/images/icons/request.png'
import callIcon from '../../assets/images/icons/call.png'
import trackIcon from '../../assets/images/icons/track.png'
import linkIcon from '../../assets/images/icons/link.png'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { apiCall } from '../../services/apiservices.jsx';

const Home = () => {
  const [customerName, setCustomerName] = useState('');
  const navigate = useNavigate();
  const [submitModal, setSubmitModal] = useState(false)
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('user-cred');
    if (token) {
      try {
        const user = JSON.parse(token);
        setCustomerName(user.user.customer_name || 'Guest');
      } catch (error) {
        console.error("Error decoding token:", error);
        setCustomerName('Guest');
      }
    }
  }, []);

  // Function to handle API check before navigating to Help & Support
  const handleHelpSupportClick = async (e) => {
    e.preventDefault(); 

    try {

      const response = await apiCall({ endpoint: "checkAccountLinkTableForDataToEnterHelpAndSupportPage", method: 'GET'});

      if (response.success) {
        navigate(`/helpsupport`);
      } else {
        setSubmitModal(true);
        setResponseMessage("No Data Found On Account Link")
      }

    } catch (error) {
      console.error("API error:", error);
    }
  };

  function closeModalOnSubmit(){
    setSubmitModal(false)
  }

  const buttons = [
    { path: '/accountlink', icon: linkIcon, label: 'Account Link' },
    { path: '/trackmessage', icon: trackIcon, label: 'Track' },
    {
      path: '/helpsupport',
      icon: helpSupportIcon,
      label: 'Help & Support',
      onClick: handleHelpSupportClick,
    },
    { path: '/trackrequest', icon: requestIcon, label: 'My Request' },
    { path: '/contact', icon: callIcon, label: 'Contact Us' },
  ];

  return (
    <>
      <Header title={customerName} arrowIcon={profileICon} profileIconClass="d-none" />
      <div className="ContentContainer">
        <section className="SlideContain">
          <AutoplaySlider />
        </section>

        <div className="ContentCard">
          <div className="Content_head">
            <div className="Content_left">
              <p>Welcome To, <span className="Bright">Manthan It Solutions</span></p>
              <p>We thank you for being our valued customer!</p>
            </div>
            <div className="Content_right"><img src={Logo} alt="img" /></div>
          </div>

          <div className="DetailsCard">
            <div className="Contain">
              <p className="Title">Manthan IT solutions</p>
              <table className="Table">
                <thead>
                  <tr>
                    <th><p>RM Details</p></th>
                    <th><p className="text-end">RM Contact No.</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p className="PBold">CS Team</p></td>
                    <td><p className="PBold text-end">1800-137-0808</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="mb-0">RM Email</p>
            <p className="PBold Word_breack pt-1">cs.team@manthanitsolutions.com</p>
          </div>
        </div>

        <div className="BtnContain">
          <div className="row gy-4">
            {buttons.map((button, index) => (
              <div key={index} className={`col-4 ${index % 3 === 0 ? 'ps-0' : ''} ${index % 3 === 2 ? 'pe-0' : ''}`}>
                <Link to={button.path} onClick={button.onClick}>
                  <div className={`IconBtn ${index % 3 === 0 ? 'me-auto' : ''} ${index % 3 === 2 ? 'ms-auto' : ''}${index % 3 === 1 ? 'm-auto' : ''}`}>
                    <div><img src={button.icon} alt="icon" /></div>
                    <span>{button.label}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />

      <div className={`SubmitModalContain ${submitModal ? '' : 'd-none'}`}>
            <div className="SubmitModal">
                <ReportProblemIcon className='SubmitIConCheck'/>
                <p>{responseMessage}.</p>
                <button onClick={() => {closeModalOnSubmit();
                  navigate(`/home`);
                }}>OK</button>
        </div>
      </div>
    </>
  );
};

export default Home;
