import React, { useEffect, useState } from "react";
import "../../assets/css/customer/trackrequest.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import ticketIcon from "../../assets/images/icons/ticket.png";
import RadioButtonGroup from "../../components/RadioGroup";
import Header from "../../components/Header";
import DashboardCards from "../../components/DashboardCard";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { apiCall } from "../../services/apiservices";
import SetLoadingScreen from "../../components/SetLoader";
import { useUIContext } from "../../context";
import { ToastContainer, toast } from "react-toastify";

const TrackRequest = () => {
  const [open, setOpen] = React.useState(false);

  const [getTotalCountData, setCountData] = useState(0);
  const [getOpenCountData, setOpenCountData] = useState(0);
  const [getClosedCountData, setClosedCountData] = useState(0);
  const [AccountDate, setAccountDate] = useState([]);
  const [selectedDataValue, setSelectedValue] = useState([]);
  const { loading, setloading } = useUIContext();
  const [rating, setRating] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [resolvedByEmployeeId, setResolvedByEmployeeId] = useState(null);

  const handleOpen = (ticketId, employeeName, mobileNumber, customerId, resolvedByEmployeeId) => {
    setCurrentTicketId(ticketId);
    setEmployeeName(employeeName);
    setMobileNumber(mobileNumber);
    setCustomerId(customerId);
    setResolvedByEmployeeId(resolvedByEmployeeId);
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen1(false);
    setRating("");
    setCurrentTicketId(null);
  };

  const handleRating = (index) => {
    setRating(index + 1);
  };

  const getDataForPageOnload = async () => {
    // const token = localStorage.getItem('token');
    // const parsedToken = jwtDecode(token);
    // const customer_id = parsedToken.customerID;
    setloading(true);
    try {
      const selectedValue = document.querySelector(
        'input[name="radio"]:checked'
      ).value;

      let endpoint = `myRequest/getDataOnloadPageForTrackRequest?radioBtnValue=${selectedValue}`;

      let response = await apiCall({ endpoint: endpoint, method: "GET" });

      if (response.success) {
        setloading(false);
        setCountData(response.data.totalCount);
        setOpenCountData(response.data.openCount);
        setClosedCountData(response.data.closeCount);
        let accountData = response.data.ticketData.map((item) => ({
          ticketId: item.ticket_id,
          createdDate: item.create_date,
          categoryType: item.issue_type,
          remarks: item.comment,
          status: item.status,
          crm_remarks: item.crm_remarks,
          mobileNumber: item.mobile_number,
          customerId: item.customer_id,
          resolvedByEmpId: item.resolved_by,
          resolvedBy: item.resolved_by_name,
          ratingVal: item.rating_val,
          reopenStatus: item.reopen_status
        }));
        setAccountDate(accountData);
      }
    } catch (error) {
      setloading(false);
      console.log("Error on fetching request: ", error);
    }
  };
  
  useEffect(() => {
    getDataForPageOnload();
  }, [selectedDataValue]);

  const handleRadioChange = (value) => {
    setloading(true);
    setSelectedValue(value);
  };

  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "My Request",
    link: "/trackrequest",
  };

  // rating resolved tickets
  const handleRatingSubmit = async (rating, ticketId, employeeName, mobileNumber, customerId, resolvedByEmployeeId) => {
    if(rating < 1){
      return toast.error('Please Select Rating');
    }
    try{
      let endpoint = `myRequest/resolvedRating`;
      let payload = {
        'ticketId': ticketId,
        'mobileNumber': mobileNumber,
        'rating': rating,
        'employeeName': employeeName,
        'employeeId': resolvedByEmployeeId,
        'customerId': customerId
      }
      const res = await apiCall({endpoint: endpoint, method: 'POST', payload: payload});
      if(res.success){
        getDataForPageOnload();
      }
    }catch(error){
      console.log('Error on uploading rating data: ', error);
    }
  };

  const ticketData = [
    { count: `${getTotalCountData}`, label: "Total", className: "" },
    { count: `${getOpenCountData}`, label: "Pending", className: "pending" },
    { count: `${getClosedCountData}`, label: "Resolved", className: "resolve" },
  ];

  return (
    <>
      <SetLoadingScreen loading={loading} />
      <Header title="Track Your Request" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <DashboardCards
          title="Tickets Dashboard"
          invoiceIcon={ticketIcon}
          ticketData={ticketData}
        />

        <div className="TrackRequest mt-3">
          <div className="TrackRequestHeader">
            <RadioButtonGroup
              options={["thisMonth", "lastMonth", "all"]}
              initialOption="thisMonth"
              onChange={handleRadioChange}
            />
          </div>
        </div>
        <div className="AccountLinkCardContain">
          {AccountDate.length > 0
            ? AccountDate.map((item, index) => (
                <div
                  className="TrackDetails RequestDetails"
                  key={item.id || index}
                >
                  <span
                    className={`CardCount ${
                      item.status === "In-Process" ? "colorRed" : "ColorGreen"
                    }`}
                  >
                    {item.status}
                  </span>
                  <div className="row gy-3">
                    <div className="col-12">
                      <div className="TrackMessageCard">
                        <h4>
                          Ticket Id : <span>{item.ticketId}</span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="TrackMessageCard">
                        <h4>
                          Date : <span>{item.createdDate}</span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="TrackMessageCard">
                        <h4>
                          Category : <span>{item.categoryType}</span>
                        </h4>
                      </div>
                    </div>
                    {item.status === "Resolved" && (
                      <div className="col-12">
                        <div className="TrackMessageCard">
                          <h4>
                            Resolved By : <span>{item.resolvedBy}</span>
                          </h4>
                        </div>
                      </div>
                    )}

                    {item.status === "Resolved" && (
                      <div className="col-12">
                        <div className="TrackMessageCard MessageCard mt-0">
                          <h4>Crm Remarks :</h4>
                          <p>{item.crm_remarks}</p>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <div className="TrackMessageCard MessageCard">
                        <h4>Remarks :</h4>
                        <p>{item.remarks}</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="TrackMessageCard">
                        <div>
                          <div className="rate_btn">
                            <div className="rate_btn_box rate_btn_box1">
                              {/* {item.status === "Resolved" && (
                                <button
                                  className="RequestModalBtn_new RequestModalBtn"
                                  onClick={() => handleOpen(item.ticketId, item.resolvedBy, item.mobileNumber, item.customerId, item.resolvedByEmpId)}
                                >
                                  Rate Us
                                </button>
                              )} */}

{item.status === "Resolved" && (
    item.ratingVal && item.reopenStatus !== '1'  ? (
        [0, 1, 2, 3, 4].map((index) => (
            <span
                key={index}
                style={{
                    color: index < item.ratingVal ? "#ffb400" : "#ccc", // Yellow for rated, red for remaining
                    fontSize: "30px",
                    margin: "0 5px",
                }}
            >
                {index < item.ratingVal ? "★" : "★"}
            </span>
        ))
    ) : (
        <button
            className="RequestModalBtn_new RequestModalBtn"
            onClick={() => handleOpen(item.ticketId, item.resolvedBy, item.mobileNumber, item.customerId, item.resolvedByEmpId)}
        >
            Rate Us
        </button>
    )
)}



                              <button
                                className="RequestModalBtn"
                                onClick={() => setOpen(!open)}
                              >
                                Contact Us
                              </button>
                            </div>
                            {/* Modal */}
                            {open1 && (
                              <div className="modal-overlay1">
                                <div className="modal1">
                                  <h2>Give Rate Us</h2>
                                  <div className="star_rate">
                                    {[0, 1, 2, 3, 4].map((index) => (
                                      <div
                                        key={index}
                                        onClick={() => handleRating(index)}
                                        style={{
                                          color:
                                            index < rating ? "#ffb400" : "#ccc",
                                          fontSize: "30px",
                                          margin: "0 5px",
                                        }}
                                      >
                                        {index < rating ? "★" : "☆"}
                                      </div>
                                    ))}
                                  </div>
                                  <div style={{ marginTop: "10px" }}>
                                    <button
                                      className="button1 button1_close"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className="button1"
                                      onClick={() => {
                                        handleRatingSubmit(
                                          rating,
                                          currentTicketId,
                                          employeeName,
                                          mobileNumber,
                                          customerId,
                                          resolvedByEmployeeId
                                        );
                                        handleClose();
                                      }}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="modal-overlay-background"
                                  onClick={handleClose}
                                ></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`RequestModalContain ${
                        open === false ? "d-none" : ""
                      }`}
                    >
                      <div className="RequestModal">
                        <h4 className="CardTitle">
                          Contact Details{" "}
                          <button
                            className="CloseBtn"
                            onClick={() => setOpen(!open)}
                          >
                            <CloseIcon />
                          </button>
                        </h4>
                        <ul>
                          <li>
                            <a
                              href="mailto:cs.team@manthanitsolutions.com"
                              target="_blank"
                              rel="noreferrer"
                              className="Word_breack"
                            >
                              <MailIcon /> cs.team@manthanitsolutions.com
                            </a>
                          </li>
                          <li>
                            <a href="tel:18001370808">
                              <CallIcon /> 1800-137-0808
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
      <ToastContainer />
      <Footer modifiedItem={modifiedItem} />
    </>
  );
};

export default TrackRequest;
