import { apiCall } from "./apiservices";


export const SignUpUser = async({payload}) =>{

  try{

    const response = await apiCall({
      endpoint: "auth/SignUp",
      method: "POST",
      payload: payload
    })  

    return response;

  }catch(error){
    throw error;
  }
  
}

export const verifyOTP = async({payload}) =>{
  try {
 
    const response = await apiCall({
      endpoint: "auth/verifyOTPForSignUp",
      method: "POST",
      payload: payload, 
    });

    return response;

  } catch (error) {
    throw error;
  }
}

export const LoginUser = async ({ payload }) => {
  try {
    const response = await apiCall({
      endpoint: "auth/log-in",
      method: "POST",
      payload: payload, 
    });
    if (response?.success) {
      const obj = {
        user: response?.data,
        token: response?.token,
      };
      localStorage.setItem("user-cred", JSON.stringify(obj));
      return response;
    }
    return response;
  } catch (error) {
    localStorage.removeItem("user-cred");
    throw error;
  }
};


export const Me = async()=>{
    const user = JSON.parse(localStorage.getItem('user-cred'))
    if(user && user?.token){
      return  await apiCall({endpoint:'auth/me', method: 'GET'})
    }
}