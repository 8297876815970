import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "../../assets/css/employe/employepending.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import requestIcon from "../../assets/images/icons/request.png";
import { apiCall } from "../../services/apiservices.jsx";
import SetLoadingScreen from "../../components/SetLoader.jsx";
import SearchIcon from "@mui/icons-material/Search";
import { useUIContext } from "../../context/index.jsx";

const EmployePending = () => {
  const { status } = useParams();
  const [currentStatus, setCurrentStatus] = useState(status);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const currentMonthIndex = new Date().getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectMonth, setSelectMonth] = useState(months[currentMonthIndex]);
  const { loading, setloading } = useUIContext();

  useEffect(() => {
    // setCurrentStatus(status);
    fetchData(currentStatus, selectMonth, debouncedSearchTerm);
  }, [currentStatus, selectMonth, debouncedSearchTerm]);

  // Apply debounce for search Input
  useEffect(()=>{
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchData = async (status, month, search) => {
    setloading(true);
    const endPoint = `employee/getStatusWiseDataForToDoPage?status=${status}&month=${month}&search=${search}`;
    try {
      const res = await apiCall({ endpoint: endPoint, method: "GET" });
      setData(res.success ? res.data.statusWiseData || [] : []);
      setloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setloading(false);
    }
  };

  const handleMonthChange = async (event, status) => {
    setSelectMonth(event.target.value);
  };

  return (
    <>
      <SetLoadingScreen loading={loading} />
      <Header title={currentStatus} arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <div className="PendingHeader pending_box_n">
        <>
          <input type="text" 
            placeholder="Search Ticket No."
            value={searchTerm}
            onChange={(e)=> setSearchTerm(e.target.value)}
            className="search_input_pending"
          />
          <SearchIcon className="search-icon search_icon_n" />
          </>
          <div className="pending_filter">
         <div className="data_no">
             <h4>
            {currentStatus} : <span>{data.length}</span>
          </h4>
          </div>
          <div className="PendingDropDown">
            <select
              name="pending"
              id="pending"
              value={selectMonth}
              onChange={handleMonthChange}
            >
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          </div>

          
        </div>
        {data.map((item, index) => (
          <div className="TrackDetails" key={index}>
            <div className="TrackMessageCard">
              <h4>{item.issue_type}</h4>
              <span className="CardCount">{index + 1}</span>
            </div>

            <div className="row gy-3 mt-2">
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>
                    Ticket Id : <span>{item.ticket_id}</span>
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <div className="TrackMessageCard  ">
                  <h4>
                    Customer Details : <span>{item.customer_name}</span>
                  </h4>
                </div>
              </div>
              
              <div className="col-12">
                <div className="TrackMessageCard CustomizeLists">
                  <h4>
                    Date : <span>{item.create_date}</span>
                  </h4>
                  <h4>Mobile Number</h4>
                </div>
              </div>
              {/* resolved by */}
              {/* {currentStatus === 'Resolved' &&(<div className="col-12">
                <div className="TrackMessageCard MessageCard mt-0">
                  <h4>Resolved By : </h4>
                  <p>{item.resolved_by_name}</p>
                </div>
              </div>)} */}

              <div className="col-12">
                <div className="TrackMessageCard CustomizeLists">
                  <h4>
                    Age : <span>{item.daysDifference} days</span>
                  </h4>
                  <h4>{item.mobile_number}</h4>
                </div>
              </div>

              <div className="col-12">
                <div className="TrackMessageCard MessageCard mt-0">
                  <h4>Remarks :</h4>
                  <p>{item.comment}</p>
                </div>
              </div>

              <div className="Devider"></div>

              {currentStatus === "In-Process" && (
                <div className="col-6">
                  <div className="TrackMessageCard MessageCard mt-0">
                    <h4>
                      Last Followed By 
                    </h4>
                    <p>{item.last_followed_name}</p>
                  </div>
                </div>
              )}

              {currentStatus === "In-Process" && (
                <div className="col-6">
                  <div className="TrackMessageCard MessageCard mt-0 text-end">
                    <h4>
                      Last Followup Date 
                    </h4>
                    <p className="text-end">{item.followup_date}</p>
                    {/* <p></p> */}
                  </div>
                </div>
              )}
              
              {currentStatus === "Resolved" && (
                <div className="col-6">
                  <div className="TrackMessageCard MessageCard mt-0">
                    <h4>
                      Resolved By
                    </h4>
                    <p>{item.resolved_by_name}</p>
                  </div>
                </div>
              )}

              {currentStatus === "Resolved" && (
                <div className="col-6">
                  <div className="TrackMessageCard MessageCard mt-0 text-end">
                    <h4>
                      Resolved Date
                    </h4>
                    <p className="text-end">{item.resolved_date}</p>
                  </div>
                </div>
              )}


              {/* <div className="col-6">
                <div className="TrackMessageCard text-end">
                  <span className={currentStatus === 'In-Process' ? 'Colorblue' : currentStatus === 'Resolved' ? 'ColorGreen' : 'colorRed'}>{currentStatus}</span>
                </div>
              </div> */}

              {/* crm remarks */}
              {(currentStatus === "Resolved" ||
                currentStatus === "In-Process") && (
                <div className="col-12">
                  <div className="TrackMessageCard MessageCard mt-0">
                    <h4>Crm Remarks :</h4>
                    <p>{item.crm_remarks}</p>
                  </div>
                </div>
              )}

             

              <div className="col-12">
                <div className="TrackMessageCard MessageCard mt-0">
                  {item.status === "Resolved" && (
                    <div className="rate_btn_box rate_btn_box1 rate_btn_box2">
                      <h4>Rating : </h4>
                      <div className="showing_rate">
                        {item.rating_val
                          ? [0, 1, 2, 3, 4].map((index) => (
                              <span
                                key={index}
                                style={{
                                  color:
                                    index < item.rating_val
                                      ? "#ffb400"
                                      : "#ccc",
                                  fontSize: "25px",
                                  margin: "0 5px",
                                }}
                              >
                                {index < item.rating_val ? "★" : "★"}
                              </span>
                            ))
                          : "No Rating to show"}
                      </div>
                      <div className="showHistory">
                        <Link to={`/employe/followup/history/${item.ticket_id}`}>
                          <button className="RequestModalBtn">History</button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {currentStatus !== "Resolved" && (
                <div className="col-12">
                  <div className="TrackMessageCard">
                    <Link
                      to={`/employe/followup/${item.ticket_id}`}
                      state={{
                        id: item.ticket_id,
                        cDate: item.create_date,
                        issue_type: item.issue_type,
                        comment: item.comment,
                        createdTime: item.create_time,
                        statusData: currentStatus,
                      }}
                    >
                      <button className="RequestModalBtn">Action</button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Footer
        modifiedItem={{
          index: 1,
          icon: requestIcon,
          label: currentStatus,
          link: `/employee/${currentStatus}`,
        }}
      />
    </>
  );
};

export default EmployePending;
