import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import seePasswordIcon from "../../assets/images/icons/eye.png";
import hidePasswordIcon from "../../assets/images/icons/eye1.png";
import { ToastContainer, toast } from "react-toastify";
import { apiCall } from "../../services/apiservices";

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = location.state?.rowData;
  const [eyeIcon, setEyeIcon] = useState(false);
  const [eyeIconSec, setEyeIconSec] = useState(false);
    const [credentials, setCredentials] = useState({
        newPassword: "",
        confirmPassword: ""
    });
  const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};

        if(!credentials.newPassword.trim()){
            formErrors.newPassword = 'New Password is Required'
        }

        if(!credentials.confirmPassword.trim()){
            formErrors.confirmPassword = 'Confirm Password is Required'
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    }

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setCredentials((prevState) => ({ ...prevState, [name]: value }));
      };

  const handleResetSubmit = async(e) => {
    e.preventDefault();
    if(!validateForm()){
        return;
    }
    if(credentials.newPassword !== credentials.confirmPassword){
        return toast.error('New Password and Confirm Password do not match');
    }
    try{
        let endpoint = `auth/resetNewPassword/`;
        let payload = {
            newPassword: credentials.newPassword,
            confirmPassword: credentials.confirmPassword,
            token: token
        }

        let res = await apiCall({endpoint: endpoint, method: 'POST', payload});

        if(res.success){
          navigate('/');
        }
    }catch(error){
        console.log('Error on changing password');
    }
  }
  return (
    <>
      <div className="ForgotPswrdContain">
        <div className="ForgotPswrdLogo">
          <img src={Logo} alt="img" />
        </div>

        <div className="ForgotPswrdCard">
          <div className="ForgotPswrdTitle">
            <h4>Forgot Password</h4>
          </div>

          <div className="LoginGroup">
            <label htmlFor="id">
              New Password <span className="RequieredStar">*</span>
            </label>
            <div className="PasswrdInpt">
              <input
                type={eyeIcon ? "text" : "password"}
                placeholder="Enter Confirm Password"
                id="newPassword"
                name="newPassword"
                value={credentials.newPassword}
                onChange={handleChanges}
              />
              {errors.newPassword && (
                  <span className="error">{errors.newPassword}</span>
                )}
              <button
                type="button"
                onClick={() => setEyeIcon(!eyeIcon)}
              >
                <img
                  src={eyeIcon ? hidePasswordIcon : seePasswordIcon}
                  alt="Toggle visibility"
                />
              </button>
            </div>
          </div>

          <div className="LoginGroup">
            <label htmlFor="password">
              Confirm Password <span className="RequieredStar">*</span>
            </label>
            <div className="PasswrdInpt">
              <input
                type={eyeIconSec ? "text" : "password"}
                id="password"
                name="confirmPassword"
                value={credentials.confirmPassword}
                placeholder="Enter New Password"
                onChange={handleChanges}
              />
              {errors.confirmPassword && (<span className="error">{errors.confirmPassword}</span>)}
              <button
                type="button"
                onClick={() => setEyeIconSec(!eyeIconSec)}
              >
                <img
                  src={eyeIconSec ? hidePasswordIcon : seePasswordIcon}
                  alt="Toggle visibility"
                />
              </button>
            </div>
          </div>
          <div className="LoginGroup LoginBtn">
            <button type="submit" onClick={handleResetSubmit}>Submit</button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
