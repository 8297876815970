import React, { useEffect, useState } from 'react';
import '../assets/css/components/header.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Profile2Icon from "../assets/images/icons/profile1.png";
import logoutIcon from "../assets/images/icons/logout.png";
import { useUIContext } from '../context';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { apiCall } from '../services/apiservices';

const Header = ({ title, arrowIcon, profileIconClass }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setHome, SelectImage, chatUserProfile, setChatUserProfile, typingStatus, setTypingStatus, onlineStatus, setOnlineStatus } = useUIContext();
  const { id } = useParams(); // Get the user `id` from URL params
  // const [pendingCount, setPendingCount] = useState('');

  // Find the specific user's profile based on the `id`
  const user = chatUserProfile ? chatUserProfile.find(user => user.id === id) : null;

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      setHome("/admin/home");
    } else if (location.pathname.includes("/employe")) {
      setHome('/employe/home');
    } else {
      setHome('/home');
    }
  }, [location.pathname, setHome]);

  const handleClick = (event) => {
    const validHomes = ['/home', '/admin/home', '/employe/home'];
    if (validHomes.includes(location.pathname)) {
      event.preventDefault();
      setOpen(!open);
    } else {
      navigate(-1);
    }
  };


  // pending notification
  // useEffect(() => {
  //   const getDashboardDataForEmployee = async () => {
  //     try {
  //       let res = await apiCall({ endpoint: 'admin/getTickets', method: 'GET' });
  //       if (res.success) {
  //         setPendingCount(res.data)
  //       }
  //     } catch (error) {
  //       console.error("Error fetching dashboard data:", error);
  //     }
  //   };
  
  //   // Call the function initially
  //   getDashboardDataForEmployee();
  
  //   // const intervalId = setInterval(getDashboardDataForEmployee, 10000);
  
  //   // return () => clearInterval(intervalId);
  // })
  
  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <section id="Header" className="ContactHeader">
      <div className='profile_text'>
      <div className={profileIconClass === 'd-none' ? 'HeaderContain' : "HeaderContain ProfileHeader"}>
        <div className={profileIconClass === 'd-none' ? 'ProfileIcon' : "ProfileIcon bg_transparant"}>
          <Link onClick={handleClick}>
            <img src={location.pathname === "/home" || location.pathname === "/admin/home" || location.pathname === "/employe/home" ? SelectImage : arrowIcon} alt="Back" />
          </Link>
        </div>
        
        <h2 className="HeaderTitle">{profileIconClass === 'd-none' ? 'Hi, ' : " "}{title}</h2>
        </div>
        {/* <p className='bell_icon_new'>
          <NotificationsActiveIcon className='header_icon_1' />
           <span>{8}</span>
        </p> */}
        
        <div className={`ProfileIcon ${profileIconClass || ''}`} onClick={() => setOpen(!open)}>
          {/* If the user is found, display their `userProfile`, otherwise fallback to `SelectImage` */}
          <img src={user ? user.userProfile : SelectImage} alt="Profile" />
        </div>
        
        {user ? '' : <ul className={`LogoutCard ${profileIconClass === 'd-none' ? 'RightL' : ''} ${open === false ? 'd-none' : ''}`}>
          <li><Link to='/profile'><img src={Profile2Icon} alt="icon" />Profile</Link></li>
          <li><Link to='/' onClick={handleLogout}><img src={logoutIcon} alt="icon" />Logout</Link></li>
        </ul>}
      </div>

      {user ? (<div className="status mb-5">
      <p className="typing-status set_status">
        {typingStatus === 'Typing...' ? 'Typing...' : (onlineStatus === 'Online' ? 'Online' : '')}
      </p>
      </div>) : ''}
    </section>
  );
};

export default Header;
