import React from 'react'
import '../../assets/css/admin/adminhome.css'
import profileICon from '../../assets/images/icons/profile.png'
// import invoiceIcon from '../../assets/images/icons/invoice.png'
// import walletIcon from '../../assets/images/icons/wallet.png'
import requestIcon from '../../assets/images/icons/request.png'
// import callIcon from '../../assets/images/icons/call.png'
import trackIcon from '../../assets/images/icons/track.png'
import linkIcon from '../../assets/images/icons/link.png'
// import paymentHistoryIcon from '../../assets/images/icons/payment-history.png'
import Footer from '../../components/Footer.jsx'
import { Link } from 'react-router-dom'
import Header from '../../components/Header.jsx'
import { useState,useEffect } from 'react'
import DashboardCards from '../../components/DashboardCard.jsx'
import ticketIcon from "../../assets/images/icons/ticket.png";
import { apiCall } from '../../services/apiservices.jsx'


const AdminHome = () => {
    const buttons = [
        { path: '/admin/accountlink', icon: linkIcon, label: 'Acount Link' },
        { path: '/admin/trackmessage', icon: trackIcon, label: 'Track' },
        { path: '/admin/todorequest', icon: requestIcon, label: 'To-Do' },
      ];

      
  const [customerName, setCustomerName] = useState('');
  const [pendingCount, setPendingCount] = useState(0);
  const [inProcessCount, setInProcessCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('user-cred');
    if (token) {
      try {
        const user = JSON.parse(token);
        setCustomerName(user.user.customer_name || 'Guest');
      } catch (error) {
        console.error("Error decoding token:", error);
        setCustomerName('Guest');
      }
    }else{
      setCustomerName('Guest');
    }
  }, []);

  // fetch dashboard count
  useEffect(()=>{
    const getDashboardDataForAdmin = async ()=>{
      let res = await apiCall({endpoint: 'admin/getAdminDashboardData', method: 'GET'});

      if(res.success){
        setPendingCount(res.data.pending);
        setInProcessCount(res.data.inProcess);
        setResolvedCount(res.data.resolved);
      }
    }
    getDashboardDataForAdmin();
  }, [])
const ticketData1 = [
    { count: `${pendingCount}`, label: 'Pending', className: '' },
    { count: `${inProcessCount}`, label: 'In-Process', className: ' pending' },
    { count: `${resolvedCount}`, label: 'Resolved', className: ' resolve' },
];

  return (
    <>
    <Header title={customerName} arrowIcon={profileICon} profileIconClass="d-none"/>


    <div className="ContentContainer">


    {/* <DashboardCards title="Admin Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} /> */}
    <DashboardCards title="To-Do Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData1} />

        
        <div className="BtnContain">
          <div className="row gy-4">
            {buttons.map((button, index) => (
              <div key={index} className={`col-4 ${index % 3 === 0 ? 'ps-0' : ''} ${index % 3 === 2 ? 'pe-0' : ''}`}>
                <Link to={button.path}>
                  <div className={`IconBtn ${index % 3 === 0 ? 'me-auto' : ''} ${index % 3 === 2 ? 'ms-auto' : ''}${index % 3 === 1 ? 'm-auto' : ''} `}>
                    <div><img src={button.icon} alt="icon" /></div>
                    <span>{button.label}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

    </div>
    <Footer/>


    </>
  )
}

export default AdminHome